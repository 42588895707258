@font-face{
  font-family: ethnocentric;
  src: url("../src/fonts/ethnocentric_rg.otf")
}
@font-face{
  font-family: bungee;
  src: url("../src/fonts/Bungee-Regular.ttf")
}
@font-face{
  font-family: poppins;
  src: url("../src/fonts/Poppins-Regular.ttf")
}
@font-face{
  font-family: amatic;
  src: url("../src/fonts/AmaticSC-Bold.ttf")
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}