.backdrop {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: inset 0 0 5em 1em #000;
    transition: background-image 4s ease-in-out;
    filter: brightness(0.5);
    position: absolute;
  }

  