.floating-text {
    position: fixed;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 24px;
    line-height: 1.7;
    max-width: 40vw;
    font-family: Amatic;
    z-index: 1;
  }