.navbar {
    top: 0;
    right: 20px;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    background: transparent;
    z-index: 1;
    font-family: amatic, sans-serif;
  }
  
  .navbar a {
    margin-left: 20px;
    text-decoration: none;
    color: white;
  }
  
  .navbar .social-icons {
    margin-left: 20px;
  }

  .disabled {
    color: grey !important;
  }