
  .floating-box {
    position: fixed;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ebedd7;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: black;
    height: 50vh;
    width: 20%;
  }

  .floating-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.8));
    border-radius: 10px;
    z-index: -1;
  }

